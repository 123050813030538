function initAnimateInView() {
    $(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
    animateElementsInView();
}

function animateElementsInView() {
    $('[data-animate-in-view]').each(function () {
        if (isElementInViewport($(this))) {
            $(this).addClass($(this).data('animate-in-view'));
        }
    });
}

function isElementInViewport(el) {
    //special bonus for those using jQuery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
        ((t >= 0) && (t <= h)) ||
        ((b >= 0) && (b <= h))
    ) {
        return true;
    } else {
        return false;
    }
}

function sliderVimeoVideo() {
    if ($('.slider__section .slider__video #vimeo-player').length > 0) {
        var iframePlayer = $('iframe#vimeo-player');
        var player = new Vimeo.Player(iframePlayer);

        // Hide play button if video is autoplaying but do show the volume controls
        if (iframePlayer.attr('src').indexOf('autoplay=1') > -1) {
            $('#volumeSwitch').toggleClass('off');
            $('#volumeOn').fadeOut(0);
            $('#volumeOff').fadeIn(0);
            $('#volumeSwitch').prop('checked', true);
        }

        $('.slider__video-bar input[type="checkbox"]').on('change', function () {
            player.getVolume().then(function (volume) {
                if ((volume == 0) || (volume == 1)) {
                    player.setVolume(0.66);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').fadeOut(0);
                    $('#volumeOn').fadeIn(0);
                    $('#volumeSwitch').prop('checked', false);
                } else {
                    player.setVolume(0);
                    $('#volumeSwitch').toggleClass('on');
                    $('#volumeSwitch').toggleClass('off');
                    $('#volumeOff').fadeIn(0);
                    $('#volumeOn').fadeOut(0);
                    $('#volumeSwitch').prop('checked', true);
                }
            })
        })
    }
}

function coverViewport(element, scaleToParent) {
    if ($(window).innerWidth() <= 1920) {
        $(element).css('height', '');
        $(element).css('width', '');
        $(element).css('top', '');
        return;
    }

    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('top', '0');
    }
}

function homeVideoAnimation() {
    var $element = $('.slider__video');
    var initialTransform = -24.3155;

    $element.addClass('slider__video-half');

    $(window).on('scroll', function () {
        var scrollTop = $(this).scrollTop();
        var maxScroll = $('header').innerHeight();
        if (scrollTop <= maxScroll) {
            $element.addClass('slider__video-half');
            var scrollPercent = scrollTop / maxScroll;
            var transformValue = initialTransform + (scrollPercent * Math.abs(initialTransform));
            $element.css('transform', 'translateY(' + transformValue + '%) translateX(-50%)');
            $('.slider__video').removeClass('slider__video-animate');
            $('header').removeClass('header__animate');
        } else {
            $('.slider__video').addClass('slider__video-animate');
            $('header').addClass('header__animate');
            $element.removeClass('slider__video-half');
            $element.css('transform', 'translateY(0%) translateX(-50%)');
        }
    });

    if ($('.slider__video iframe').length > 0) {
        coverViewport('.slider__video iframe', true);
        $(window).on('resize', function () {
            coverViewport('.slider__video iframe', true);
        });
    }
}

function setArrowHeight(slideImageClass, sliderClass) {
    var slideImageHeight = $(slideImageClass).outerHeight();
    $(sliderClass).css('--arrow-height', slideImageHeight + 'px');
}

function sliderWithFilter() {
    var $nav = $('.sliderfilter__item');

    if ($nav.length == 0) {
        return;
    }

    var $slider = $('.sliderfilter__slider');

    $($nav).on('click', function () {
        $('.sliderfilter__item.active').removeClass('active');
        $(this).addClass('active');
    });

    $($nav).on('click', function () {
        var filter = $(this).data('filter');
        $($slider).slick('slickUnfilter');

        if (filter != 'all') {
            $($slider).slick('slickFilter', '.' + filter);
        } else {
            $($slider).slick('slickUnfilter');
        }
    });

    $($slider).slick({
        autoplay: false,
        autoplaySpeed: 1200,
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 2000,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    function updateWidth() {
        var tabContent = $slider.parent();
        var windowWidth = $(window).innerWidth();
        if (windowWidth > 767) {
            tabContent.css('width', ($(window).innerWidth() - tabContent.offset().left));
        } else {
            tabContent.css('width', '');
        }
    }
    updateWidth();
    window.addEventListener('resize', function () {
        updateWidth();
    });
}

function accommodationSliderWithFilter() {
    var $nav = $('.accommodation-sliderfilter__tabs-btn');

    if ($nav.length == 0) {
        return;
    }

    var $accommodationSlider = $('.accommodation-sliderfilter__slider');

    $($nav).on('click', function () {
        $('.accommodation-sliderfilter__tabs-btn.active').removeClass('active');
        $(this).addClass('active');
    });

    $($nav).on('click', function () {
        var filter = $(this).data('filter');
        $($accommodationSlider).slick('slickUnfilter');

        if (filter != 'all') {
            $($accommodationSlider).slick('slickFilter', '.' + filter);
        } else {
            $($accommodationSlider).slick('slickUnfilter');
        }
    });

    $($accommodationSlider).slick({
        dots: false,
        autoplay: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 2000,
        centerMode: true,
        centerPadding: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    function accommodationSliderArrowHeights() {
        setArrowHeight('.accommodation-sliderfilter__slide-image', '.accommodation-sliderfilter__slider');
    }
    accommodationSliderArrowHeights();
    window.addEventListener('resize', accommodationSliderArrowHeights);
}

function initializeSliders() {
    // Hero Section Slider
    if ($('.slider__carousel .slider__carousel-slide').length > 1) {
        $('.slider__carousel').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1500
        });
    }

    // Homepage Offer Slider
    if ($('.offers__slider .offers__slide').length > 1) {
        $('.offers__slider').slick({
            autoplay: true,
            autoplaySpeed: 1200,
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            speed: 2000,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        function offersArrowHeights() {
            setArrowHeight('.offers__slide-image', '.offers__slider');
        }
        offersArrowHeights();
        window.addEventListener('resize', offersArrowHeights);
    }

    // Experiences Slider
    if ($('.experiences__slider .experiences__slide').length > 0) {
        $('.experiences__slider').slick({
            autoplay: true,
            autoplaySpeed: 1200,
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 2000,
            centerMode: true,
            centerPadding: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        function experiencesArrowHeights() {
            setArrowHeight('.experiences__slide-image', '.experiences__slider');
        }
        experiencesArrowHeights();
        window.addEventListener('resize', experiencesArrowHeights);
    }

    // Accommodation Slider
    if ($('.accommodation__slider .accommodation__slide').length > 0) {
        $('.accommodation__slider').slick({
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 2000,
            centerMode: true,
            centerPadding: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        function accommodationArrowHeights() {
            setArrowHeight('.accommodation__slide-image', '.accommodation__slider');
        }
        accommodationArrowHeights();
        window.addEventListener('resize', accommodationArrowHeights);
    }

    // Accommodation Details Gallery Slider
    if ($('.accommodation-gallery__slider .accommodation-gallery__slide, .calendar-gallery__slider .calendar-gallery__slide').length > 1) {
        $('.accommodation-gallery__slider, .calendar-gallery__slider').slick({
            autoplay: false,
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 2000,
        });
    }

    // Calendar Event Slider
    if ($('.calendar-event__slider .calendar-event__slide').length > 0) {
        $('.calendar-event__slider').slick({
            dots: false,
            autoplay: true,
            arrows: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 2000,
            centerMode: true,
            centerPadding: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        function calendarArrowHeights() {
            setArrowHeight('.calendar-event__slide-image', '.calendar-event__slider');
        }
        calendarArrowHeights();
        window.addEventListener('resize', calendarArrowHeights);
    }

    // Wedding Celebration Slider
    if ($('.weddingCelebration__slider .weddingCelebration__slide').length > 0) {
        $('.weddingCelebration__slider').slick({
            autoplay: true,
            autoplaySpeed: 1200,
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 2000,
            centerMode: true,
            centerPadding: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        function weddingCelebrationArrowHeights() {
            setArrowHeight('.weddingCelebration__slide-image', '.weddingCelebration__slider');
        }
        weddingCelebrationArrowHeights();
        window.addEventListener('resize', weddingCelebrationArrowHeights);
    }
}

function initializeStickyHeader() {
    window.addEventListener('scroll', function () {
        var height = 0;
        if ($('.slider__section').length > 0) {
            height = $('.slider__section').innerHeight();
        } else if ($('.accommodation-intro__section').length > 0) {
            height = $('.accommodation-intro__section').innerHeight();
        }
        if (height == 0 || window.scrollY > height) {
            $('header').addClass('header__sticky');
        } else if (window.scrollY < (height - 50)) {
            $('header').removeClass('header__sticky');
        }
    });
}

function resortDropdown() {
    $('.offcanvas__resort-selected').on('show.bs.dropdown', function () {
        $('.offcanvas__main-nav .navbar-nav').addClass('invisible');
    });

    $('.offcanvas__resort-selected').on('hide.bs.dropdown', function () {
        $('.offcanvas__main-nav .navbar-nav').removeClass('invisible');
    });
}

function offerFilter() {
    var $nav = $('.offerfilter__tabs-btn');
    var $item = $('.offerfilter__item');
    $($nav).on('click', function () {
        var filter = $(this).data('filter');

        $($nav).removeClass('active');
        $(this).addClass('active');

        $($item).hide();

        if (filter === 'all') {
            $($item).show();
        } else {
            $('.' + filter).show();
        }
    });
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
function generateMonths() {
    const today = new Date();  // Get current date
    var currentMonth = today.getMonth();  // Get current month (0-11)
    var currentYear = today.getFullYear();  // Get current year

    var monthsHtml = '';

    // Loop through the next 12 months
    for (var i = 0; i < 12; i++) {
        var monthIndex = (currentMonth + i) % 12;
        var year = currentYear + Math.floor((currentMonth + i) / 12);

        // Add year heading if first month or a new year starts
        if (i === 0 || monthIndex === 0) {
            monthsHtml += '<label>' + year + '</label>';
        }

        var activeClass = '';
        if (i === 0) {
            activeClass = ' active';
        }

        // Create month button
        monthsHtml += '<button class="btn btn-outline-black'+activeClass+'" data-month="'+monthIndex+'">' + months[monthIndex] + '</button>';
    }

    return monthsHtml;
}
function bookingWidget() {

    // Destination
    $('.static-search__select select').each(function (index, element) {
        $(this).parent().after().append('<div class="static-search__select-list"><div class="static-search__select-option"></div><ul></ul></div>');
        $(element).each(function (idx, elm) {
            $('option', elm).each(function (id, el) {
                if (!$(el).is(':disabled')) {
                    $('.static-search__select-list ul:last').append('<li data-value="' + $(el).val() + '">' + el.text + '</li>');
                }
            });
            $('.static-search__select-list ul').hide();
        });
        var defaultText = $(this).find('option:selected').text();
        $('.static-search__select-list:last').children('div.static-search__select-option').text(defaultText);
    });

    $('.static-search__select-option').on('click', function () {
        $(this).next('ul').slideToggle(200);
        $('.static-search__select-option').not(this).next('ul').hide();
    });

    $('.static-search__select-list ul li').on('click', function () {
        var selectedLI = $(this).text();
        var selectedValue = $(this).data('value');
        $(this).parent().prev('.static-search__select-option').text(selectedLI);
        $(this).closest('.static-search__select-list').prev('select').val(selectedValue).trigger('change');
        $(this).parent('ul').hide();

        enableButton();
    });

    $('.static-search__select-list').show();
    $('.static-search__select select').addClass('d-none');

    // Datepicker
    $('.static-search__datepicker-range').daterangepicker({
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        locale: {
            format: 'ddd, MMM YY',
            day: 'long',
            month: 'long',
            year: 'numeric',
            cancelLabel: 'Reset',
        }
    });

    function resetDates() {
        $('#check_in_text').val('Add Dates');
        $('#check_out_text').val('Add Dates');
        $('#check_in').val('');
        $('#check_out').val('');
        $('#date').val('Check In – Check Out');
        $('#nightsCount').text(1);

        var today = new Date();
        $('.static-search__datepicker-range').data('daterangepicker').setStartDate(today);
        $('.static-search__datepicker-range').data('daterangepicker').setEndDate(new Date(today.getFullYear, (today.getMonth() - 1), today.getDate() + 1));
        $('.static-search__datepicker-range').data('daterangepicker').updateCalendars();

        $('.static-search__datepicker-range').click();

        $('.static-search__datepicker-month-selection .btn').removeClass('active');
        $('.static-search__datepicker-month-selection .btn').eq(0).addClass('active');
    }

    $('.static-search__datepicker-range').on('apply.daterangepicker', function (ev, picker) {
        $('#check_in_text').val(picker.startDate.format('ddd, MMM YY'));
        $('#check_out_text').val(picker.endDate.format('ddd, MMM YY'));
        $('#check_in').val(picker.startDate.format('YYYY/MM/DD'));
        $('#check_out').val(picker.endDate.format('YYYY/MM/DD'));
        $('#date').val(picker.startDate.format('ddd, MMM YY') + ' - ' +picker.endDate.format('ddd, MMM YY'));
        $('.static-search__datepicker-range').click();

        enableButton();
    });

    $('.static-search__datepicker-range').on('cancel.daterangepicker', function (ev, picker) {
        resetDates();
    });

    $('.static-search__datepicker #date').on('click', function () {
        if ($('#check_in_text').val() == '') {
            $('#check_in_text').val('Add Dates');
        }
        if ($('#check_out_text').val() == '') {
            $('#check_out_text').val('Add Dates');
        }
        $('.static-search__select-list ul').hide();
        $('.static-search__datepicker-dropdown').toggleClass('open');
        $('.static-search__datepicker-range').click();
    });

    $(document).mouseup(function (e) {
        var dateInput = $('#date');
        var container = $('.static-search__datepicker-dropdown');
        if (!container.is(e.target) && container.has(e.target).length === 0 && !dateInput.is(e.target)) {
            $('.static-search__datepicker-dropdown').removeClass('open');
        } else {
            $('.static-search__datepicker-range').click();
        }
    });

    if ($('.static-search__datepicker-info').length > 0) {
        $('.static-search__datepicker-info').prepend($('.daterangepicker.show-calendar'));
    }

    $('button[role="tab"]').on('show.bs.tab', function () {
        $('.static-search__datepicker-range').click();
    });

    $('.static-search__datepicker-month-selection').append(generateMonths());

    function updateFlexibleDate() {
        var nights = parseInt($('#nightsCount').text());
        var selectedMonth = $('.static-search__datepicker-month-selection .btn.active');
        var month = selectedMonth.attr('data-month');
        var year = selectedMonth.prevAll('label').eq(0).text();

        var startDate = new Date(year, month, 1);
        var endDate = new Date(year, month, 1+nights);
        $('#date').val(selectedMonth.text()+' '+ year);

        $('#check_in_text').val(days[startDate.getDay()]+', '+ startDate.toLocaleString('default', { month: 'short' }) +' '+startDate.getFullYear().toString().slice(-2));
        $('#check_out_text').val(days[endDate.getDay()]+', '+ endDate.toLocaleString('default', { month: 'short' }) +' '+endDate.getFullYear().toString().slice(-2));
        $('#check_in').val(startDate.getFullYear()+'/'+ ('0'+(startDate.getMonth()+1)).slice(-2) + '/' + ('0' + startDate.getDate()).slice(-2));
        $('#check_out').val(endDate.getFullYear()+'/'+ ('0'+(endDate.getMonth()+1)).slice(-2) + '/' + ('0' + endDate.getDate()).slice(-2));

        $('.static-search__datepicker-range').data('daterangepicker').setStartDate(startDate);
        $('.static-search__datepicker-range').data('daterangepicker').setEndDate(endDate);
        $('.static-search__datepicker-range').data('daterangepicker').updateCalendars();
    }

    $('.static-search__datepicker-month-selection .btn').on('click', function (e) {
        e.preventDefault();
        $('.static-search__datepicker-month-selection .btn').removeClass('active');
        $(this).addClass('active');

        updateFlexibleDate();
    });

    $('#increaseNights').on('click', function (e) {
        e.preventDefault();
        var nights = parseInt($('#nightsCount').text());
        if (nights < 31) {
            nights += 1;
            $('#nightsCount').text(nights);
            updateFlexibleDate();
        }
    });

    $('#decreaseNights').on('click', function (e) {
        e.preventDefault();
        var nights = parseInt($('#nightsCount').text());
        if (nights > 1) {
            nights -= 1;
            $('#nightsCount').text(nights);
            updateFlexibleDate();
        }
    });

    $('.cancelBtn').on('click', function (e) {
        e.preventDefault();
        resetDates();
    });

    $('.doneBtn').on('click', function (e) {
        e.preventDefault();
        $('.static-search__datepicker-dropdown').toggleClass('open');
        enableButton();
    });

    function enableButton() {
        var resortVal = ($('#resort').val() || '');
        var checkInVal = ($('#check_in').val() || '');
        var checkOutVal = ($('#check_out').val() || '');
        var guestsVal = ($('#guests').val() || '');

        if (resortVal !== '' && checkInVal !== '' && checkOutVal !== '' && guestsVal !== '') {
            $('#checkAvailability').prop('disabled', false);
        } else {
            $('#checkAvailability').prop('disabled', true);
        }
    }
}

function socialGallerySlider() {
    if ($('.social-marquee__lfr').length > 0) {
        $('.social-marquee__lfr').slick({
            autoplay: true,
            autoplaySpeed: 0,
            arrows: false,
            dots: false,
            slidesToShow: 5,
            infinite: true,
            cssEase: 'linear',
            centerMode: true,
            variableWidth: true,
            pauseOnHover: true,
            speed: 7500
        });
    }

    if ($('.social-marquee__rfl').length > 0) {
        $('.social-marquee__rfl').slick({
            autoplay: true,
            autoplaySpeed: 0,
            arrows: false,
            dots: false,
            slidesToShow: 5,
            infinite: true,
            cssEase: 'linear',
            centerMode: true,
            variableWidth: true,
            pauseOnHover: true,
            rtl: true,
            speed: 7500
        });
    }
}

function initializeSliderAndAccordion(sliderSelector, accordionSelector, headingSelector, contentSelector, listSelector) {
    var $slider = $(sliderSelector);
    $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 600,
        arrows: true,
        dots: false,
        prevArrow: '<button class="slick-prev-arrow"></button>',
        nextArrow: '<button class="slick-next-arrow"></button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                }
            }
        ]
    });

    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        handleAccordionWithSlider(nextSlide);
    });

    $(accordionSelector).on('click', headingSelector, function () {
        if (!$(this).hasClass('active')) {
            $(accordionSelector + ' ' + headingSelector).removeClass('active');
            var index = $(this).parent().index();
            $slider.slick('slickGoTo', index);
            $(this).toggleClass('active').next().slideDown(100);
            $(contentSelector).not($(this).next()).slideUp(100);
        }
    });

    function handleAccordionWithSlider(currentSlide) {
        var $accordionItem = $(accordionSelector + ' ' + listSelector).eq(currentSlide).find(headingSelector);
        $(accordionSelector + ' ' + headingSelector).removeClass('active');
        $accordionItem.addClass('active').next().slideDown(100);
        $(contentSelector).not($accordionItem.next()).slideUp(100);
    }

    handleAccordionWithSlider(0);
}

function calendarFilter() {
    const $nav = $('.calendar__filter li'),
        $datesDropdown = $('#dates'),
        $loadMoreButton = $('.calendar__section .calendar__button a'),
        $events = $('.calendar__section .upcoming-events__content .upcoming-events__item');
    var itemsPerPage = parseInt($("#calendarLimit").val() || 3),
        visibleItems = itemsPerPage;

    $events.hide().slice(0, visibleItems).show();

    toggleLoadMoreButton();

    function toggleLoadMoreButton() {
        const filter = $datesDropdown.val();
        const filteredCount = $events.filter(function () {
            return filter === 'all' || $(this).hasClass(filter);
        }).length;
        $loadMoreButton.toggle(visibleItems < filteredCount);
    }

    function filterEvents() {
        const filter = $datesDropdown.val();
        $events.hide();
        visibleItems = itemsPerPage;
        $events.filter(function () {
            return filter === 'all' || $(this).hasClass(filter);
        }).slice(0, itemsPerPage).show();
        toggleLoadMoreButton();
    }

    function loadMoreEvents() {
        const filter = $datesDropdown.val();
        const $filteredEvents = $events.filter(function () {
            return filter === 'all' || $(this).hasClass(filter);
        });
        $filteredEvents.slice(visibleItems, visibleItems + itemsPerPage).show();
        visibleItems += itemsPerPage;
        toggleLoadMoreButton();
    }

    $nav.on('click', filterEvents);
    $loadMoreButton.on('click', function (e) {(e.preventDefault(), loadMoreEvents())});
}

// Umbraco Forms Label
function umbracoForms() {
    $(".umbraco-forms-field-wrapper label + *").focus(function () {
        $(this).closest('.umbraco-forms-field-wrapper').addClass("focused typing");
    }).blur(function () {
        if ($(this).val() == '' || $(this).val() == null) {
            $(this).closest('.umbraco-forms-field-wrapper').removeClass("focused typing");
        } else if ($(this).val() != '' || $(this).val() != null) {
            $(this).closest('.umbraco-forms-field-wrapper').addClass("focused").removeClass('typing');
        }
    });
}

// Images Parallax Scroll
$(window).on('load scroll', function () {
    var parallaxElement = $('.brandSignature__parallax-image'),
    parallaxQuantity = parallaxElement.length;
    window.requestAnimationFrame(function () {
        for (var i = 0; i < parallaxQuantity; i++) {
            var currentElement = parallaxElement.eq(i),
            windowTop = $(window).scrollTop(),
            elementTop = currentElement.offset().top,
            elementHeight = currentElement.height(),
            viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
            scrolled = windowTop - elementTop + viewPortHeight;
            currentElement.css({
                transform: 'translate3d(0,' + scrolled * -0.12 + 'px, 0)'
            });
        }
    });
});

$(document).ready(function () {

    if ($('body').hasClass('homepage')) {
        $(window).scrollTop(0);
        homeVideoAnimation();
    }
    initAnimateInView();
    sliderVimeoVideo();
    sliderWithFilter();
    accommodationSliderWithFilter();
    initializeSliders();
    initializeStickyHeader();
    resortDropdown();
    offerFilter();
    bookingWidget();
    socialGallerySlider();
    calendarFilter();
    initializeSliderAndAccordion('.resort-experiences__image-slider','.resort-experiences__accordion','.resort-experiences__accordion-heading','.resort-experiences__accordion-content','.resort-experiences__accordion-list');
    initializeSliderAndAccordion('.weddingLocation__image-slider','.weddingLocation__accordion','.weddingLocation__accordion-heading','.weddingLocation__accordion-content','.weddingLocation__accordion-list');
    umbracoForms();
});